<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM2.8 7.19995V17H10.22V15.04H4.9V12.94H8.96V11.05H4.9V9.15995H10.08V7.19995H2.8ZM12.3905 7.19995V17H14.4905V10.91L19.2505 17H21.1405V7.19995H19.0405V13.29L14.2805 7.19995H12.3905Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
