<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM2.8 7.19995V17H4.9V13.78H6.86L8.61 17H10.92L8.89 13.36C9.21667 13.22 9.49667 13.024 9.73 12.772C10.29 12.212 10.57 11.4513 10.57 10.49C10.57 9.50062 10.2573 8.70728 9.632 8.10995C9.016 7.50328 8.18533 7.19995 7.14 7.19995H2.8ZM7.07 11.82H4.9V9.15995H7.07C7.462 9.15995 7.77933 9.28595 8.022 9.53795C8.274 9.78062 8.4 10.098 8.4 10.49C8.4 10.882 8.274 11.204 8.022 11.456C7.77933 11.6986 7.462 11.82 7.07 11.82ZM12.8811 7.19995V12.94C12.8811 14.2 13.2778 15.2266 14.0711 16.02C14.8644 16.8133 15.8911 17.21 17.1511 17.21C18.4111 17.21 19.4378 16.8133 20.2311 16.02C21.0244 15.2266 21.4211 14.2 21.4211 12.94V7.19995H19.3211V12.94C19.3211 13.6306 19.1204 14.1906 18.7191 14.62C18.3178 15.04 17.7951 15.25 17.1511 15.25C16.5071 15.25 15.9844 15.04 15.5831 14.62C15.1818 14.1906 14.9811 13.6306 14.9811 12.94V7.19995H12.8811Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
