<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM3.55 15.0431V16.9331C3.578 16.9425 3.61067 16.9565 3.648 16.9751C3.68533 16.9845 3.72733 16.9938 3.774 17.0031C3.82067 17.0218 3.858 17.0358 3.886 17.0451C4.12867 17.1105 4.41333 17.1431 4.74 17.1431C6.20533 17.1431 7.302 16.3731 8.03 14.8331L11.67 7.20312H9.22L7.26 11.8371L4.95 7.20312H2.5L6.14 13.8531L6 14.1331C5.64533 14.8331 5.132 15.1831 4.46 15.1831C4.19867 15.1831 3.97467 15.1598 3.788 15.1131C3.72267 15.1038 3.64333 15.0805 3.55 15.0431ZM12.7834 7.20312V17.0031H14.8834V14.1331L16.1434 12.8031L19.0134 17.0031H21.4634L17.5434 11.2631L21.1834 7.20312H18.6634L14.8834 11.4031V7.20312H12.7834Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
